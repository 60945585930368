.journey-page-section {
  margin-top: 5em;
  /* padding: 7% 0; */
  text-align: center;
}
.journey-page-section img {
  max-width: 100%;
  height: 100%;
  /* max-height: 50em; */
  object-fit: cover;
  /* filter: grayscale(100%); */
  /* transition: 0.9s filter ease; */
}
.journey-page-heading {
  margin-bottom: 0.5em;
}
.journey-rugs-main-paragraph {
  max-width: 50em;
  display: flex;
  align-self: center;
}

.journey-rug-container-main {
  /* margin: 0 0 7em 0; */
  height: 100vh;
  display: grid;
  grid-template-rows: min-content 0.85fr min-content;
  justify-items: center;
  align-content: center;
}
.journey-main-image-container {
  overflow: hidden;
  margin: 1.5rem 0;
}
.journey-main-image {
  max-width: 100%;
  /* height: 100%; */
  /* height: 60vh; */
  object-fit: cover;
  /* object-position: center -10em; */
}
.journey-rug-container {
  height: 100vh;
  margin: 2rem 0;
}
.journey-rugs-heading {
  /* margin-top: 0.5em; */
  margin-bottom: 0;
}
.journey-rug-heading {
  /* margin-top: 0.5em; */
}
.journey-rug-paragraph {
  max-width: 40em;
  margin: 0.5em auto;
}

.journey-half-container {
  display: grid;
  grid-template-rows: 0.1fr 0.75fr min-content;
  gap: 1.5em;
  align-content: center;
}
.journey-half-image-container {
  overflow: hidden;
  /* background-color: red; */
}
.journey-half-container img {
  max-width: 55em;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* margin: 1.5rem 0; */
}
.journey-full-container {
  position: relative;
  padding: 2em 0;
}

.journey-full-container img {
  max-width: 100%;

  min-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.journey-full-info {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: white;
}

.carding-spinning-container {
  color: rgba(255, 255, 255, 0);
}

.carding-spinning-info {
  /* position: relative; */
  color: black;
  margin: 8em 0;
}

/* img:hover {
  filter: grayscale(0);
} */

.journey-page-header img {
  max-width: 100%;
  /* width: 100%; */
  max-height: 30em;
}

@media (max-width: 768px) {
  .journey-rugs-heading {
    margin: 0 0.3em;
  }
  .journey-rugs-main-paragraph {
    padding: 0 1em;
  }
  .journey-page-section img {
    height: 100%;
    object-fit: cover;
  }
  .journey-rug-container-main {
    height: 90vh;
    grid-template-rows: min-content 0.8fr min-content;
  }
  .journey-rug-paragraph {
    margin: 0;
  }
  .journey-full-container img {
    height: 100%;
  }
  .journey-rug-paragraph {
    max-width: none;
    margin: 0.5em 1.5em;
  }

  .journey-half-container p {
    margin: 0 1.5em;
  }
}
