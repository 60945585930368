@import url(https://fonts.googleapis.com/css2?family=Cinzel:wght@300;400;500&family=Josefin+Sans:wght@300;400&family=Lora:wght@400;500&family=Prata&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

#vijaya-laxmi-heading-font {
  font-family: var(--landing-page-font);
  font-weight: 500;
}

ul {
  list-style-type: none;
}

li {
  display: inline;
}

button {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  border-color: white;
  border-style: solid;
  border-width: 0.5px;
}
.landing-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* background: linear-gradient(236.64deg, #fefefe 32.77%, #e7e7e7 96.68%); */
  /* background-color: rgb(64, 83, 64); */

  /* grid-template-rows: 0.3fr 1fr 0.3fr; */
  /* justify-content: center; */
}
.landing-page-main-content {
  background-image: url(/static/media/landing-image.676c04fe.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center left;
  display: flex;
  /* align-self: center; */
  align-items: center;
  justify-self: center;
  margin: 6.5em 0 4em 0;
  height: 100%;
  flex-basis: 3;
}
.landing-image {
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  transform: rotate(90deg);
  object-fit: cover;
  height: 100%;
  width: auto;
}

.landing-header {
  text-align: center;
}

.landing-heading {
  font-family: var(--alternate-font);
  font-weight: 300;
  /* text-transform: uppercase; */
  width: 100%;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  padding: 3em;
  background-color: rgba(88, 79, 79, 0.39);
  color: white;
  text-align: center;
}
.landing-heading h1 {
  letter-spacing: 10px;
}
.landing-heading h2 {
  font-size: clamp(1rem, 2vw, 2rem);
}

.landing-heading p {
  margin-top: 0.5em;
  font-size: clamp(1rem, 1.5vw, 2rem);
}
.logo-container {
  height: 100px;
  width: 100px;
  background-color: aqua;
}

.journey {
  height: 70vh;
  max-width: 80%;
  margin: 6em auto;
  position: relative;
  display: flex;
  justify-content: center;
  font-family: var(--alternate-font);
}
.journey-header-container {
  /* height: 30vh; */
  min-width: 100%;
  /* background-color: red; */
}
.journey-image {
  height: 60vh;
  width: 100%;
  object-fit: cover;
  box-shadow: 0px 42px 61px 7px rgba(0, 0, 0, 0.25);
}

.journey-header {
  position: absolute;
  color: white;
  text-align: center;
  width: 100%;
  left: auto;
  top: calc(30vh - 7vw);
  letter-spacing: 10px;

  /* margin-top: 8.5%; */
  text-transform: uppercase;
}

.journey-content {
  position: absolute;
  top: 50%;
  background: linear-gradient(4.29deg, #000000 5.95%, #505050bb 96.58%);
  max-width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 6% 6%;
}

.journey-content-paragraph {
  font-family: var(--primary-font);
  font-weight: 400;
  text-align: center;
  margin: 1.5em auto;
  font-size: clamp(0.9rem, 1.5vw, 3rem);
}

.journey-content-follow-journey {
  font-family: var(--primary-font);
  cursor: pointer;
  opacity: 1;
  text-decoration: underline;
  color: #ffffff;
  transition: opacity 1000ms ease-in, transform 400ms ease-in;
}

.collections {
  /* margin-top: 10em; */
  display: grid;
  /* grid-template-rows: 1fr 0.8fr 0.8fr  ; */
  /* height: 230vh; */
}

.collection-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  text-transform: uppercase;
  min-height: 75vh;
}
.collection-item:nth-child(3) {
  min-height: 65vh;
}

#collections-heading {
  font-family: var(--primary-font);
  font-weight: 400;
  letter-spacing: 0;
}

.view-collection-button {
  margin-top: 1em;
  padding: 1rem 2.5rem;
  font-size: 1rem;
  text-transform: uppercase;
  transition: color 0.3s, background-color 0.5s ease-in-out;
}

.view-collection-button:hover {
  cursor: pointer;
  color: black;
  background-color: white;
}

.ceramics-home-button:hover {
  cursor: default;
  background-color: transparent;
  color: white;
}

.appear {
  opacity: 1;
  transform: translateY(0);
}
.blog {
  margin-top: 2em;
  text-align: center;
  font-family: var(--primary-font);
  font-weight: 300;
}

.blog-header {
  font-family: var(--primary-font);
  font-weight: 400;
  text-transform: uppercase;
  padding: 0.5em;
  position: relative;
}

.blog-item-heading {
  line-height: 3rem;
  font-weight: 400;
}

.blog-item-subheading {
  line-height: 3rem;
  font-weight: 300;
}
.blog-items {
  display: flex;
  /* width: auto; */
  /* grid-template-columns: 1fr 1fr; */
  height: 30em;
}

.blog-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 2em;
  color: white;
  font-size: 1rem;
  height: 30em;
  background-size: cover;
}

.blog-item:hover {
  cursor: pointer;
  color: rgb(228, 227, 227);
}

.newsletter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  padding: 2em;
  background-color: rgb(0, 0, 0);
  color: white;
}
.button-textfield {
  /* background-color: red; */
  /* width: 15em; */
  display: flex;
}
.subscribe {
  font-family: var(--alternate-font);
  text-align: end;
  padding-right: 2em;
}

.newsletter-textfield input[type="text"] {
  width: 20em;
  margin-right: 0.5em;
  padding: 0.65em;
  box-sizing: border-box;
}

.subscribe-button {
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5em 1.5em;
}

@media (max-width: 768px) {
  .landing-heading h1 {
    letter-spacing: 0;
  }
  #vijaya-laxmi-heading-font:nth-child(2) {
    margin-bottom: 0.5em;
  }
  .journey {
    max-width: 100%;
  }
  .journey-header {
    top: calc(23vh - 7vw);
    letter-spacing: 0px;
  }
  .journey-content {
    max-width: 90%;
    padding: 4em 2em;
    top: 55%;
  }

  .journey-content-paragraph {
    margin: auto;
    margin-bottom: 1em;
  }

  .blog-items {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .blog-item {
    justify-content: center;
    align-items: center;
  }
  .newsletter {
    display: flex;
    flex-direction: column;
  }

  .button-textfield {
    display: flex;
    flex-direction: column;
  }

  input[type="text"] {
    width: 15em;
    justify-self: center;
    align-self: center;
    margin-right: 0;
    /* padding: 0.65em; */
  }

  .subscribe {
    text-align: center;
    padding-right: 0;
    margin-bottom: 0.5em;
  }
  .subscribe-button {
    width: 100%;
    margin-top: 1rem;
    padding: 0.5em 1em;
  }
}

.loading {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

:root {
  --landing-page-font: "Cinzel", serif;
  --primary-font: "Josefin Sans", sans-serif;
  --alternate-font: "Prata", sans-serif;
  /* --secondary-font: coral; */
  --content-font: "Lora", serif;

  --blog-line-width: 0em;
}

label {
  font-family: "Lora", serif;
  font-family: var(--content-font);
}
.heading-1 {
  font-family: "Prata", sans-serif;
  font-family: var(--alternate-font);
  font-size: clamp(1.5rem, 3.5vw, 4rem);
}
.heading-2 {
  font-family: "Lora", serif;
  font-family: var(--content-font);
  font-size: clamp(1.2rem, 2.5vw, 3.5rem);
  /* letter-spacing: 0.3em; */
}
.heading-3 {
  font-family: "Prata", sans-serif;
  font-family: var(--alternate-font);
  font-size: clamp(1rem, 1.8vw, 3.5rem);
}
.heading-4 {
  font-family: "Prata", sans-serif;
  font-family: var(--alternate-font);
  font-size: clamp(0.9rem, 1.5vw, 3.5rem);
}
.heading-5 {
  font-family: "Josefin Sans", sans-serif;
  font-family: var(--primary-font);
  font-size: 1.3rem;
}

.paragraph-component {
  font-family: "Josefin Sans", sans-serif;
  font-family: var(--primary-font);
  /* color: #ccbebe; */
  font-size: clamp(0.8rem, 1.4vw, 2rem);
  line-height: 1.5em;
}

.dropDownText {
  font-family: "Josefin Sans", sans-serif;
  font-family: var(--primary-font);
  /* color: #ccbebe; */
  font-size: clamp(1.1rem, 1vw, 2rem);
  line-height: 1.7rem;
  font-weight: 400;
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5em 4em;
  background: #1b1b1b;
  color: white;
  font-family: var(--primary-font);
}

.footer-left {
  text-align: justify;
  max-width: 35em;
}
.footer-left-heading {
  line-height: 2.5em;
  margin-bottom: 0.5em;
}
.footer-left-content {
  font-weight: lighter;
  line-height: 1.6em;
  color: white;
}
.social-media-icons,
.footer-left-heading,
.footer-address,
.footer-email {
  font-weight: 300;
  color: white;
}

.footer-address {
  margin: 0.2em;
}

.social-media-icons {
  font-size: 1.8rem;
}
.social-media-icons:hover {
  cursor: pointer;
}
.social-media-icons + .social-media-icons {
  margin-left: 0.5em;
}

.footer-right {
  text-align: center;
}

.footer-right p {
  margin-top: 0.5em;
}

@media (max-width: 768px) {
  footer {
    flex-direction: column;
    justify-content: center;
    padding: 3em 0;
  }
  .footer-left {
    text-align: center;
  }
  .footer-left-heading {
    line-height: 2.5em;
  }
  .footer-left-content {
    padding: 0;
    margin: 0.5em 2em;
  }
  .footer-address {
    margin-top: 1em;
  }

  .social-logo {
    font-size: 2rem;
  }

  .footer-left {
    max-width: none;
  }

  .footer-right {
    margin-top: 1em;
    text-align: center;
  }
}

.collectionSection {
  text-align: center;
  padding: 0% 0 2% 0;
  /* outline: 1px solid black; */
  /* outline-offset: 2em; */
  overflow-x: hidden;
}
.collectionPageHeading {
  margin: 0.5em 0;
}

.collectionWrapper {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 1em;
  gap: 1em;
  margin-top: 6.5em;
}

a[id="Founders"] {
  grid-column: 1/3;
  grid-row: 2/3;
  min-height: 30em;
}

#Basics {
  grid-column: 2/3;
  grid-row: 3/5;
  width: 100%;
}
#Runner {
  grid-column: 1/3;
  min-height: 25em;
}

.collectionItem {
  text-decoration: none;
  /* margin: 2em 0; */
  display: flex;
  /* flex: 40%; */
  justify-content: center;
  align-items: center;
  color: white;
  min-height: 25em;
  transition: all 0.1s linear;
}

.collectionItem:hover {
  transform: scale(1.01);
  /* transition: all 0.1s ease-in-out; */
}

@media (max-width: 768px) {
  .collectionWrapper {
    display: flex;
    flex-direction: column;
  }

  a[id="Founder's Collection"] {
    grid-column: 1/2;
    grid-row: 2/3;
  }

  #Basics {
    grid-column: 1/2;
    grid-row: 3/4;
    width: 100%;
  }
}

.close-modal-box {
  position: relative;
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  height: 2.3em;
  width: 2.3em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-modal-button::after,
.close-modal-button::before {
  content: "";
  inset: 0;
  margin: auto;
  position: absolute;
  width: 1.5em;
  height: 0.25em;
  background: rgb(255, 255, 255);
}
.close-modal-button::after {
  content: "";
  /* left: 0; */
  /* right: 0; */

  transform: rotate(45deg);
  /* top: -0.5em; */
}
.close-modal-button::before {
  /* top: 0.5em; */
  transform: rotate(130deg);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.individualCollectionSection {
  margin-top: 7em;
  /* margin-left: 10em; */
  margin-bottom: 2em;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.individual-collection-header {
  height: 90vh;
  width: 100%;
  display: grid;
  grid-template-rows: 0.9fr -webkit-min-content;
  grid-template-rows: 0.9fr min-content;
  grid-gap: 1.5em;
  gap: 1.5em;
  justify-items: center;
}
.individual-collection-nav {
  /* background-color: rgb(95, 25, 25); */
  width: 100%;
}
.individualCollectionBanner {
  /* margin: 2em; */
  /* min-height: 35em; */
  width: 100%;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}
.individualCollectionBanner h2 {
  font-weight: lighter;
  background-color: rgba(255, 255, 255, 0.904);
  color: black;
  text-align: center;
  width: 100%;
  padding: 1em 1em;
}
.individual-collection-description {
  text-align: center;
  align-self: flex-start;
  max-width: 70em;
  padding: 0 5em;
}
.individual-collection-description h2 {
  margin-bottom: 0.5em;
}

.individualCollectionItems {
  /* margin: 1em; */
  margin-bottom: 1em;
  /* max-width: 1300px; */
  min-width: 70%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 1em;
  gap: 1em;
  background-color: white;
}
.individualCollectionItem {
  /* justify-self: center; */
  /* align-self: center; */
  /* flex-basis: 10em; */
  display: flex;
  /* background-color: rgb(180, 54, 54); */
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  height: auto;
  min-height: 25em;
  background-position: center;

  /* width: max-content; */
  min-width: 20em;
  margin-bottom: 2em;
  flex-basis: 25%;
}
.individualCollectionRugName {
  opacity: 0;
  word-break: break-all;
  /* display: none; */
}
.individualCollectionItem:hover {
  cursor: pointer;
}
.individualCollectionItem:hover .individualCollectionRugName {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.individual-collection-carpet-info {
  padding: 5em;
  display: flex;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
  /* align-items: center; */
}
.outer-popup {
  position: fixed;
  display: flex;
  align-items: center;
  background-color: rgba(39, 39, 39, 0.432);
  /* justify-content: center; */
  /* width: 100%; */
  /* height: auto; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.inner-popup {
  /* width: minmax(1rem, 2rem); */
  /* max-width: 70%; */
  position: relative;

  /* width: clamp(50%, 90px, 60%); */
  max-width: 70em;
  /* max-width: 75em; */
  background-color: rgb(240, 240, 240);
  padding: 3.5em 5em;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-gap: 2em;
  gap: 2em;
}

.individual-carpet-info-left-col {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 50vh;
  /* justify-self: center; */
  /* background-color: red; */
}
.individual-collection-main-picture {
  max-width: 100%;
  max-height: 100%;
}
.individual-collection-main-picture:hover {
  cursor: zoom-in;
}

.individual-collection-secondary-picture {
  margin-top: 1em;
  height: 3em;
  width: 3em;
}
.individual-carpet-info-right-col {
  /* background-color: rgb(216, 92, 92); */

  display: flex;
  flex-direction: column;
  grid-gap: 0.5em;
  gap: 0.5em;
  flex: 60% 1;
  /* padding-top: 1em; */
}
.form-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(51, 51, 51, 0.336);
  z-index: 100200;
  transition: all 10s ease;
}

.individual-carpet-form {
  /* height: 300px; */
  right: 0;
  bottom: 0;
  padding: 2em 1.5em 1em;
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  animation: rightToLeft 0.3s linear;
  z-index: 2147483647;
}
.close-individual-carpet-form {
  transform: translateX(100%);
}

@keyframes rightToLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.individual-carpet-form p {
  margin: 0.5em 0;
  max-width: 15em;
  /* text-align: justify; */
  /* word-break: ; */
  /* width: auto; */
}
.individual-carpet-form button {
  margin: 1.5em 0 2em;
}
.individual-carpet-form-close-button {
  margin: 1.5em 0 2em;
  background-color: white;
}
.individual-carpet-form input {
  min-height: 3.5em;
  min-width: 30em;
  margin-top: 1.5em;
}
.individual-carpet-description {
  margin: 0 0 0.5em 0;
}

.invalid-email {
  /* position: absolute; */
  color: rgba(0, 0, 0, 0.973);
  font-weight: bolder;
  padding: 0.5em 1em;
  background-color: rgb(255, 185, 185);
  border-radius: 2px;
  right: 10px;
  bottom: 10px;
}

.individual-carpet-get-quote-button {
  cursor: pointer;
  align-self: flex-start;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(0.8rem, 0.9vw, 3.5rem);
  margin-top: 0.5em;
  padding: 1em 2em;
  /* width: 20%; */
}

.individual-carpet-close-button-bottom,
.individual-carpet-form-close-button {
  cursor: pointer;
  background-color: rgb(88, 88, 88);
  color: white;
  justify-content: center;
  align-items: center;
  font-size: clamp(0.8rem, 0.9vw, 3.5rem);
  margin-top: 0.5em;
  padding: 1em 2em;
  display: none;
}
.close-popup {
  padding: 0.6em 0.7em;
  background-color: rgb(0, 0, 0);
  color: white;
  font-weight: bold;
  position: absolute;
  top: 1em;
  right: 1em;
  /* border-radius: 0.5rem; */
  transition: 0.3s background-color ease-in-out, 0.1s transform ease-in-out;
}
.close-popup:hover {
  transform: scale(1.05);
  cursor: pointer;
  background-color: rgb(0, 0, 0);
}

#img-full-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(36, 36, 36);
  z-index: 110000;
  padding: 2em 0;
}
#img-full-bg img {
  max-width: 100%;
  max-height: 50em;
}

#img-full-bg:hover {
  cursor: zoom-out;
}

@media (max-width: 768px) {
  .individual-collection-description {
    padding: 0;
  }
  .individual-collection-description p {
    margin: 0 0.5em;
  }
  .inner-popup {
    overflow-y: scroll;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    padding: 2em 0 2em 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: -webkit-min-content 1fr;
    grid-template-rows: min-content 1fr;
    justify-items: center;
    grid-gap: 2em;
    gap: 2em;
  }
  .individual-carpet-info-left-col {
    max-height: 40vh;
  }
  .close-button {
    display: none;
  }

  .individual-carpet-get-quote-button,
  .individual-carpet-form-button {
    align-self: center;
    width: 40%;

    /* maxwidth: 100%; */
    /* margin: 0 1em; */
  }
  .individual-carpet-form button {
    margin-bottom: 1em;
  }
  .individual-carpet-close-button-bottom,
  .individual-carpet-form-close-button {
    display: flex;
    margin-top: 0;
    width: 40%;
  }
  .individual-collection-carpet-info {
    padding: 0em;
  }
  .individual-carpet-info-right-col {
    flex: 100% 1;
    align-items: center;
    text-align: center;
  }
  .individual-carpet-form {
    /* height: 300px; */
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 2em 1.5em 1em;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    animation: rightToLeft 0.3s linear;
    z-index: 600;
  }
  .individual-carpet-form p,
  .individual-carpet-form h3 {
    align-self: center;
  }

  /* .individual-carpet-form button {
    width: min-content;
  } */

  .invalid-email {
    position: absolute;
    padding: 0.5em 1em;
    top: 10px;
    left: 10px;
    right: auto;
    bottom: auto;
  }
  .individual-email-input {
    margin-top: 0.5em;
  }
  .individual-carpet-description {
    margin: 0 0.5em;
  }
  .individual-collection-main-picture:hover {
    cursor: default;
  }
  #img-full-bg:hover {
    cursor: default;
  }
}

.logo-image {
  height: auto;
  width: 100%;
  max-width: clamp(6em, 4vw, 10em);
  -webkit-max-width: clamp(6em, 4vw, 10em);
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

nav {
  position: absolute;
  top: 0;
  min-width: 23em;
  display: flex;
  font-family: var(--alternate-font);
  padding: 0 3em;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  z-index: 1000;
  width: 100%;
  background-color: #ffffff;
  transition: background-color 500ms ease-in, opacity 800ms ease-in;
}

.nav-show {
  transform: translateY(0em);
  /* opacity: 1; */
}
.nav-link {
  font-size: clamp(0.8rem, 0.9vw, 3rem);
}

.nav-item {
  padding: 0.7em;
}

.nav-item a {
  text-decoration: none;
  color: rgb(49, 49, 49);
}

.nav-item a:hover {
  color: rgb(173, 175, 33);
}
.nav-collection-dropdown-hide {
  display: none;
}
#nav-rugs {
  position: relative;
}

.nav-collection-dropdown {
  position: absolute;
  left: -1em;
  /* display: none; */
}

.nav-collection-dropdown-ul {
  padding-top: 1.5em;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}
#nav-rugs:hover .nav-collection-dropdown-ul {
  max-height: 500px;
}
.nav-collection-dropdown-ul li {
  margin: 1em 1.5em;
}
.hamburger-menu {
  position: fixed;
  /* opacity: 70%; */
  top: 6%;
  right: 5%;
  width: 2.3em;
  height: 2.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1001;
  background: rgb(0, 0, 0);
}
.hamburger-menu-button {
  position: absolute;
  width: 1.5em;
  height: 0.25em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  background: rgb(255, 255, 255);
}
.hamburger-menu-button::after {
  top: -0.5em;
}
.hamburger-menu-button::before {
  top: 0.5em;
}

.hamburger-menu-button::after,
.hamburger-menu-button::before {
  content: "";
  position: absolute;
  right: 0;
  width: 1.5em;
  height: 0.25em;
  background: rgb(255, 255, 255);
  box-shadow: 0 2px 2px rgba(116, 116, 116, 0.2);
  transition: 0.5s;
}

.hamburger-menu-active .hamburger-menu-button {
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
}
.hamburger-menu-active .hamburger-menu-button::after {
  top: 0px;
  transform: rotate(45deg);
}

.hamburger-menu-active .hamburger-menu-button::before {
  top: 0px;
  transform: rotate(130deg);
}

.landing-navbar-active {
  flex-direction: column;
  padding-top: 2em;
  padding-bottom: 5em;
  top: 4%;
  width: 0;
  right: 3.5%;
  text-align: center;
  justify-content: start;
  align-items: center;
  position: fixed;
  height: auto;
  background: radial-gradient(50% 50% at 50% 50%, #ffffff 0%, #f9f9f9 100%);
  box-shadow: 0px 10px 23px 27px rgba(0, 0, 0, 0.26);
}
.nav-bottom-heading {
  margin-bottom: 2em;
}
.landing-navbar-active ul li {
  display: block;
}
.landing-navbar-active > .nav-items {
  margin-top: 1em;
}

@media (max-width: 768px) {
  nav {
    padding: 0 0.5em;
    min-width: auto;
  }
  .nav-item {
    display: none;
  }
  .hamburger-menu {
    /* opacity: 50%; */
    top: 5%;
    right: 5%;
    width: 2em;
    height: 2em;
  }
  .nav-link {
    font-size: clamp(1.6rem, 2vw, 3rem);
  }
  .landing-navbar-active {
    padding-top: 2em;
    padding-bottom: 5em;
    top: 2%;
    left: 2%;
    right: 2%;
    bottom: 5%;
    width: auto;
    min-width: auto;
  }
}

.journey-page-section {
  margin-top: 5em;
  /* padding: 7% 0; */
  text-align: center;
}
.journey-page-section img {
  max-width: 100%;
  height: 100%;
  /* max-height: 50em; */
  object-fit: cover;
  /* filter: grayscale(100%); */
  /* transition: 0.9s filter ease; */
}
.journey-page-heading {
  margin-bottom: 0.5em;
}
.journey-rugs-main-paragraph {
  max-width: 50em;
  display: flex;
  align-self: center;
}

.journey-rug-container-main {
  /* margin: 0 0 7em 0; */
  height: 100vh;
  display: grid;
  grid-template-rows: -webkit-min-content 0.85fr -webkit-min-content;
  grid-template-rows: min-content 0.85fr min-content;
  justify-items: center;
  align-content: center;
}
.journey-main-image-container {
  overflow: hidden;
  margin: 1.5rem 0;
}
.journey-main-image {
  max-width: 100%;
  /* height: 100%; */
  /* height: 60vh; */
  object-fit: cover;
  /* object-position: center -10em; */
}
.journey-rug-container {
  height: 100vh;
  margin: 2rem 0;
}
.journey-rugs-heading {
  /* margin-top: 0.5em; */
  margin-bottom: 0;
}
.journey-rug-heading {
  /* margin-top: 0.5em; */
}
.journey-rug-paragraph {
  max-width: 40em;
  margin: 0.5em auto;
}

.journey-half-container {
  display: grid;
  grid-template-rows: 0.1fr 0.75fr -webkit-min-content;
  grid-template-rows: 0.1fr 0.75fr min-content;
  grid-gap: 1.5em;
  gap: 1.5em;
  align-content: center;
}
.journey-half-image-container {
  overflow: hidden;
  /* background-color: red; */
}
.journey-half-container img {
  max-width: 55em;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* margin: 1.5rem 0; */
}
.journey-full-container {
  position: relative;
  padding: 2em 0;
}

.journey-full-container img {
  max-width: 100%;

  min-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.journey-full-info {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: white;
}

.carding-spinning-container {
  color: rgba(255, 255, 255, 0);
}

.carding-spinning-info {
  /* position: relative; */
  color: black;
  margin: 8em 0;
}

/* img:hover {
  filter: grayscale(0);
} */

.journey-page-header img {
  max-width: 100%;
  /* width: 100%; */
  max-height: 30em;
}

@media (max-width: 768px) {
  .journey-rugs-heading {
    margin: 0 0.3em;
  }
  .journey-rugs-main-paragraph {
    padding: 0 1em;
  }
  .journey-page-section img {
    height: 100%;
    object-fit: cover;
  }
  .journey-rug-container-main {
    height: 90vh;
    grid-template-rows: -webkit-min-content 0.8fr -webkit-min-content;
    grid-template-rows: min-content 0.8fr min-content;
  }
  .journey-rug-paragraph {
    margin: 0;
  }
  .journey-full-container img {
    height: 100%;
  }
  .journey-rug-paragraph {
    max-width: none;
    margin: 0.5em 1.5em;
  }

  .journey-half-container p {
    margin: 0 1.5em;
  }
}

.about-us-page {
  padding: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-us-main {
  margin-top: 6em;
  height: 100vh;
  /* width: 90vw; */
  display: grid;
  grid-template-rows: -webkit-min-content 0.9fr;
  grid-template-rows: min-content 0.9fr;
  align-content: center;
  overflow: hidden;
}

.about-us-image-container {
  overflow: hidden;
  margin: 1.5rem 0;
  position: relative;
}
.about-us-main img {
  position: relative;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  object-position: bottom center;
}
.about-us-subheading {
  position: relative;
  color: rgb(53, 53, 53);
}

.about-us-paragraph-container {
  position: absolute;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 70%;
  inset: 0;
  color: #131212;
  /* text-align: justify; */
  /* line-height: auto; */
}
.about-us-paragraph + .about-us-paragraph {
  margin-top: 2rem;
}

.our-values-section {
  padding: 3em 8em;
}

.our-values-section h2 {
  margin-bottom: 1em;
}
.values-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  gap: 1em;
  justify-items: center;
  align-items: start;
}

.value {
  background-color: rgb(248, 248, 248);
  height: 100%;
  width: 100%;
  padding: 3em 3em;
}

.value:nth-child(3) {
  grid-column: 1/3;
}

.value h3 {
  margin-bottom: 1em;
}
.value p {
  max-width: 25em;
}
.value:nth-child(3) p {
  max-width: 35em !important;
  margin-right: auto;
  margin-left: auto;
}
.our-founders-section {
  color: #ad7c56;
  margin-top: 5em;
  display: grid;
  grid-template-rows: -webkit-min-content 0.45fr -webkit-min-content;
  grid-template-rows: min-content 0.45fr min-content;
  justify-items: center;
  /* width: 100%; */
  padding: 3em 0 1em 0;
  background: #000000;
}
.our-founders-introduction {
  max-width: 50em;
}
.our-founders-introduction p {
  margin: 1rem 0;
  letter-spacing: 0.15em;
  /* color: #996b47; */
}

.our-founders-main {
  display: grid;
  grid-gap: 1em;
  gap: 1em;
  grid-template-columns: 1.5fr 1fr;
  justify-content: center;
  padding: 2em 5em;
}
.founders-image-container {
  overflow: hidden;
}
.about-us-founders-image {
  max-width: 100%;
  width: 100%;
  height: 100%;
  max-height: 45em;
  object-fit: cover;
}

.founders-info-container {
  display: grid;
  grid-template-rows: -webkit-min-content 1.5fr;
  grid-template-rows: min-content 1.5fr;
  outline-offset: 10px;
  max-width: 65em;
  outline: 1px solid rgb(216, 219, 188);
  /* max-width: 70%; */
  margin: 10px;
}

blockquote {
  /* display: flex; */
  /* justify-content: center; */
  /* border: 0.5px solid rgb(219, 219, 219); */
  padding: 4em 2em;
}
.quote-container {
  background-color: #8d6b51;
  border-radius: 2px;
  /* margin: 0 -10%; */
  /* width: calc(100% + 20%); */
  /* border: 1px solid black; */
}
.quotation {
  /* font-size: 2rem; */
  /* max-width: 350em; */
  color: rgb(255, 255, 255);
  quotes: "\201C""\201D""\2018""\2019";
  position: relative;
  font-weight: 400;
}

.quotation:before {
  content: open-quote;
  display: inline;
  height: 0;
  line-height: 0;
  left: -10px;
  position: relative;
  top: 30px;
  color: rgb(0, 0, 0);
  font-size: 3em;
}
.quotation::after {
  content: close-quote;
  display: inline;
  height: 0;
  line-height: 0;
  /* left: 20px; */
  position: absolute;
  bottom: -0.2em;
  right: 0em;
  color: rgb(0, 0, 0);
  font-size: 3em;
}
.founders-paragaraph {
  background-color: #f3f3e1;
  color: rgb(80, 80, 80);
  padding: 2em;
  display: flex;
  align-items: center;
}

.our-projects {
  margin: 5em 10% 0;
  border: 2px solid rgb(219, 219, 219);
  padding: 3em 4em;
}
.our-projects h2 {
  margin-bottom: 2rem;
}

.sarlahi-rug-container h3,
.thimi-ceramics-container h3 {
  margin-bottom: 1.1rem;
}

.thimi-ceramics-container {
  margin-top: 2rem;
  /* max-height: 35em; */
  overflow: hidden;
}

.image-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
  gap: 2em;
  margin: 0.5em 0;
}

#sarlahi-rug-image,
#thimi-ceramics-image {
  max-width: 100%;
  object-fit: cover;
  /* height: 100%; */
  /* float: left; */
}
#thimi-ceramics-image {
  max-height: 35em;
  width: 100%;
}
.thimi-ceramics-paragraph,
.sarlahi-paragraph {
  text-align: justify;
}

.sarlahi-paragraph + .sarlahi-paragraph {
  margin-top: 1rem;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

@media (max-width: 1200px) {
  .about-us-image-container {
    height: 100%;
  }
  .about-us-main img {
    min-height: 130vh;
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    object-position: bottom center;
  }
  .our-founders-main {
    grid-template-columns: 1fr;
    padding: 1em 1em;
  }
  .founders-info-container {
    margin: 1em;
  }
  .quote-container {
    padding: 0em 10em;
  }
  .founders-paragaraph {
    padding: 3em 10em;
  }
}
@media (max-width: 768px) {
  .about-us-main {
    margin-top: 5vh;
    height: auto;
    width: auto;
  }

  .our-values-section {
    padding: 2em 0 1em 0;
    margin: 2em 1.5em;
  }
  .values-grid {
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
  }
  .value {
    padding: 1em 2.5em;
  }
  .our-founders-section {
    margin-top: 1em;
    padding: 2em 0 1em 0;
  }

  .founders-info-container {
    display: flex;
    flex-direction: column;
    margin: 2em 1.5em;
  }

  blockquote {
    padding: 2em 2em;
    line-height: 2em;
  }
  .founders-paragaraph {
    /* line-height: 2rem; */
    /* max-width: 55em; */
    padding: 2em 6em;
    line-height: 2rem;
  }
  .quote-container {
    padding: 0em 1em;
  }

  .image-container {
    grid-template-columns: none;
    grid-template-rows: auto 1fr;
    margin: 0.5em 0;
  }
  .our-projects {
    margin: 2em 0 0;
    border: 2px solid rgb(219, 219, 219);
    padding: 1em;
  }
  .sarlahi-rug-container h3,
  .thimi-ceramics-container h3 {
    text-align: center;
  }
  .thimi-ceramics-paragraph,
  .sarlahi-paragraph {
    text-align: center;
  }
}
@media (max-width: 450px) {
  .about-us-main {
    margin-top: 10vh;
  }
  .about-us-main img {
    min-height: 160vh;
  }
  .about-us-paragraph-container {
    max-width: 90%;
  }
  .founders-paragaraph {
    padding: 2em 2em;
  }
}

.contact-us-top {
  margin-top: 12vh;
  text-align: center;
}

.contact-us-page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 3em;
  padding-bottom: 5em;
  padding-left: 10em;
  padding-right: 10em;
  grid-gap: 3em;
  gap: 3em;
  justify-items: end;
}
.contact-us-left {
  justify-self: flex-start;
  margin-top: 1em;
}
.contact-address,
.contact-email,
.contact-telephone {
  margin-bottom: 0.7em;
}
.contact-hear {
  margin-top: 2em;
  margin-bottom: 3em;
  font-weight: bold;
}

.contact-main-image {
  object-fit: cover;
  margin-top: 2em;
  max-width: 100%;
}
.contact-us-left h2 {
  margin-bottom: 0.2em;
}

form {
  display: flex;
  flex-direction: column;
  min-width: 30em;
  max-width: 35em;
  /* background-color: red; */
}
label {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}
form input {
  min-height: 4em;
  width: 100%;
  /* margin-top: 1.5em; */
}
input[type="text"] {
  width: auto;
  padding-left: 1em;
}
textarea {
  padding: 1em;
  resize: none;
  min-height: 15em;
  width: 100%;
}

.subscribe-newsletter {
  display: flex;
  /* align-items: center; */
}
.subscribe-newsletter label {
  margin: auto;
  margin-left: 0.5em;
}
input[type="checkbox"] {
  width: 1.5em;
}

@media (max-width: 768px) {
  .contact-us-top {
    /* margin-top: 25%; */
    text-align: center;
  }
  .contact-us-page {
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 2em;
    padding-bottom: 3em;
    padding-left: 2em;
    padding-right: 2em;
    grid-gap: 2em;
    gap: 2em;
    justify-items: start;
  }

  .contact-main-image {
    max-width: 100%;
  }
  form {
    min-width: auto;
  }
  input[type="text"] {
    width: auto;
    width: 100%;
    align-self: flex-start;
  }
  .contact-button {
    align-self: flex-start;
    margin-top: 2em;
  }
  .subscribe-newsletter {
    justify-content: start;
    align-items: flex-start;
  }
}

.communications-section {
  padding: 15vh;
  position: relative;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.communications-section img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.communications-section h1 {
  /* color: white; */
  margin-top: 2%;
  margin-bottom: 1em;
  text-align: center;
}
.communications-main {
  color: rgb(0, 0, 0);
  max-width: 70em;
  background-color: rgba(226, 224, 224, 0.877);
  padding: 5em 7em;
  display: flex;
  grid-gap: 5em;
  gap: 5em;
}

.communications-left {
  max-width: 50%;
}

.communications-left p {
  font-size: clamp(0.8rem, 1.5vw, 2rem);
  line-height: 1.4em;
}

.communications-right ul {
  margin-top: 0.5em;
}

.communications-right ul li {
  display: flex;
  margin-left: 0.5em;
  margin-top: 0.2em;
  color: rgb(0, 0, 0);
}

@media (max-width: 768px) {
  .communications-section {
    padding: 5vw;
    min-height: 80vh;
  }
  .communications-section h1 {
    /* color: white; */
    margin-top: 10vh;
  }

  .communications-main {
    max-width: auto;
    padding: 2em;
    display: flex;
    flex-direction: column;
    grid-gap: 2em;
    gap: 2em;
  }

  .communications-left {
    max-width: none;
  }
  .communications-left p {
    text-align: center;
  }
  .communications-right {
    text-align: center;
  }

  .communications-right ul li {
    justify-content: center;
    margin-left: 0;
  }
}

@media (max-width: 425px) {
  .communications-section h1 {
    /* color: white; */
    margin-top: 10vh;
  }
}

@media (max-width: 380px) {
  .communications-section h1 {
    /* color: white; */
    margin-top: 15vh;
  }
}

