footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5em 4em;
  background: #1b1b1b;
  color: white;
  font-family: var(--primary-font);
}

.footer-left {
  text-align: justify;
  max-width: 35em;
}
.footer-left-heading {
  line-height: 2.5em;
  margin-bottom: 0.5em;
}
.footer-left-content {
  font-weight: lighter;
  line-height: 1.6em;
  color: white;
}
.social-media-icons,
.footer-left-heading,
.footer-address,
.footer-email {
  font-weight: 300;
  color: white;
}

.footer-address {
  margin: 0.2em;
}

.social-media-icons {
  font-size: 1.8rem;
}
.social-media-icons:hover {
  cursor: pointer;
}
.social-media-icons + .social-media-icons {
  margin-left: 0.5em;
}

.footer-right {
  text-align: center;
}

.footer-right p {
  margin-top: 0.5em;
}

@media (max-width: 768px) {
  footer {
    flex-direction: column;
    justify-content: center;
    padding: 3em 0;
  }
  .footer-left {
    text-align: center;
  }
  .footer-left-heading {
    line-height: 2.5em;
  }
  .footer-left-content {
    padding: 0;
    margin: 0.5em 2em;
  }
  .footer-address {
    margin-top: 1em;
  }

  .social-logo {
    font-size: 2rem;
  }

  .footer-left {
    max-width: none;
  }

  .footer-right {
    margin-top: 1em;
    text-align: center;
  }
}
