* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

#vijaya-laxmi-heading-font {
  font-family: var(--landing-page-font);
  font-weight: 500;
}

ul {
  list-style-type: none;
}

li {
  display: inline;
}

button {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  border-color: white;
  border-style: solid;
  border-width: 0.5px;
}
.landing-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* background: linear-gradient(236.64deg, #fefefe 32.77%, #e7e7e7 96.68%); */
  /* background-color: rgb(64, 83, 64); */

  /* grid-template-rows: 0.3fr 1fr 0.3fr; */
  /* justify-content: center; */
}
.landing-page-main-content {
  background-image: url("../images/landing-image.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center left;
  display: flex;
  /* align-self: center; */
  align-items: center;
  justify-self: center;
  margin: 6.5em 0 4em 0;
  height: 100%;
  flex-basis: 3;
}
.landing-image {
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  transform: rotate(90deg);
  object-fit: cover;
  height: 100%;
  width: auto;
}

.landing-header {
  text-align: center;
}

.landing-heading {
  font-family: var(--alternate-font);
  font-weight: 300;
  /* text-transform: uppercase; */
  width: 100%;
  height: min-content;
  padding: 3em;
  background-color: rgba(88, 79, 79, 0.39);
  color: white;
  text-align: center;
}
.landing-heading h1 {
  letter-spacing: 10px;
}
.landing-heading h2 {
  font-size: clamp(1rem, 2vw, 2rem);
}

.landing-heading p {
  margin-top: 0.5em;
  font-size: clamp(1rem, 1.5vw, 2rem);
}
.logo-container {
  height: 100px;
  width: 100px;
  background-color: aqua;
}

.journey {
  height: 70vh;
  max-width: 80%;
  margin: 6em auto;
  position: relative;
  display: flex;
  justify-content: center;
  font-family: var(--alternate-font);
}
.journey-header-container {
  /* height: 30vh; */
  min-width: 100%;
  /* background-color: red; */
}
.journey-image {
  height: 60vh;
  width: 100%;
  object-fit: cover;
  box-shadow: 0px 42px 61px 7px rgba(0, 0, 0, 0.25);
}

.journey-header {
  position: absolute;
  color: white;
  text-align: center;
  width: 100%;
  left: auto;
  top: calc(30vh - 7vw);
  letter-spacing: 10px;

  /* margin-top: 8.5%; */
  text-transform: uppercase;
}

.journey-content {
  position: absolute;
  top: 50%;
  background: linear-gradient(4.29deg, #000000 5.95%, #505050bb 96.58%);
  max-width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 6% 6%;
}

.journey-content-paragraph {
  font-family: var(--primary-font);
  font-weight: 400;
  text-align: center;
  margin: 1.5em auto;
  font-size: clamp(0.9rem, 1.5vw, 3rem);
}

.journey-content-follow-journey {
  font-family: var(--primary-font);
  cursor: pointer;
  opacity: 1;
  text-decoration: underline;
  color: #ffffff;
  transition: opacity 1000ms ease-in, transform 400ms ease-in;
}

.collections {
  /* margin-top: 10em; */
  display: grid;
  /* grid-template-rows: 1fr 0.8fr 0.8fr  ; */
  /* height: 230vh; */
}

.collection-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  text-transform: uppercase;
  min-height: 75vh;
}
.collection-item:nth-child(3) {
  min-height: 65vh;
}

#collections-heading {
  font-family: var(--primary-font);
  font-weight: 400;
  letter-spacing: 0;
}

.view-collection-button {
  margin-top: 1em;
  padding: 1rem 2.5rem;
  font-size: 1rem;
  text-transform: uppercase;
  transition: color 0.3s, background-color 0.5s ease-in-out;
}

.view-collection-button:hover {
  cursor: pointer;
  color: black;
  background-color: white;
}

.ceramics-home-button:hover {
  cursor: default;
  background-color: transparent;
  color: white;
}

.appear {
  opacity: 1;
  transform: translateY(0);
}
.blog {
  margin-top: 2em;
  text-align: center;
  font-family: var(--primary-font);
  font-weight: 300;
}

.blog-header {
  font-family: var(--primary-font);
  font-weight: 400;
  text-transform: uppercase;
  padding: 0.5em;
  position: relative;
}

.blog-item-heading {
  line-height: 3rem;
  font-weight: 400;
}

.blog-item-subheading {
  line-height: 3rem;
  font-weight: 300;
}
.blog-items {
  display: flex;
  /* width: auto; */
  /* grid-template-columns: 1fr 1fr; */
  height: 30em;
}

.blog-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 2em;
  color: white;
  font-size: 1rem;
  height: 30em;
  background-size: cover;
}

.blog-item:hover {
  cursor: pointer;
  color: rgb(228, 227, 227);
}

.newsletter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  padding: 2em;
  background-color: rgb(0, 0, 0);
  color: white;
}
.button-textfield {
  /* background-color: red; */
  /* width: 15em; */
  display: flex;
}
.subscribe {
  font-family: var(--alternate-font);
  text-align: end;
  padding-right: 2em;
}

.newsletter-textfield input[type="text"] {
  width: 20em;
  margin-right: 0.5em;
  padding: 0.65em;
  box-sizing: border-box;
}

.subscribe-button {
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5em 1.5em;
}

@media (max-width: 768px) {
  .landing-heading h1 {
    letter-spacing: 0;
  }
  #vijaya-laxmi-heading-font:nth-child(2) {
    margin-bottom: 0.5em;
  }
  .journey {
    max-width: 100%;
  }
  .journey-header {
    top: calc(23vh - 7vw);
    letter-spacing: 0px;
  }
  .journey-content {
    max-width: 90%;
    padding: 4em 2em;
    top: 55%;
  }

  .journey-content-paragraph {
    margin: auto;
    margin-bottom: 1em;
  }

  .blog-items {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .blog-item {
    justify-content: center;
    align-items: center;
  }
  .newsletter {
    display: flex;
    flex-direction: column;
  }

  .button-textfield {
    display: flex;
    flex-direction: column;
  }

  input[type="text"] {
    width: 15em;
    justify-self: center;
    align-self: center;
    margin-right: 0;
    /* padding: 0.65em; */
  }

  .subscribe {
    text-align: center;
    padding-right: 0;
    margin-bottom: 0.5em;
  }
  .subscribe-button {
    width: 100%;
    margin-top: 1rem;
    padding: 0.5em 1em;
  }
}
