.about-us-page {
  padding: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-us-main {
  margin-top: 6em;
  height: 100vh;
  /* width: 90vw; */
  display: grid;
  grid-template-rows: min-content 0.9fr;
  align-content: center;
  overflow: hidden;
}

.about-us-image-container {
  overflow: hidden;
  margin: 1.5rem 0;
  position: relative;
}
.about-us-main img {
  position: relative;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  object-position: bottom center;
}
.about-us-subheading {
  position: relative;
  color: rgb(53, 53, 53);
}

.about-us-paragraph-container {
  position: absolute;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 70%;
  inset: 0;
  color: #131212;
  /* text-align: justify; */
  /* line-height: auto; */
}
.about-us-paragraph + .about-us-paragraph {
  margin-top: 2rem;
}

.our-values-section {
  padding: 3em 8em;
}

.our-values-section h2 {
  margin-bottom: 1em;
}
.values-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  justify-items: center;
  align-items: start;
}

.value {
  background-color: rgb(248, 248, 248);
  height: 100%;
  width: 100%;
  padding: 3em 3em;
}

.value:nth-child(3) {
  grid-column: 1/3;
}

.value h3 {
  margin-bottom: 1em;
}
.value p {
  max-width: 25em;
}
.value:nth-child(3) p {
  max-width: 35em !important;
  margin-right: auto;
  margin-left: auto;
}
.our-founders-section {
  color: #ad7c56;
  margin-top: 5em;
  display: grid;
  grid-template-rows: min-content 0.45fr min-content;
  justify-items: center;
  /* width: 100%; */
  padding: 3em 0 1em 0;
  background: #000000;
}
.our-founders-introduction {
  max-width: 50em;
}
.our-founders-introduction p {
  margin: 1rem 0;
  letter-spacing: 0.15em;
  /* color: #996b47; */
}

.our-founders-main {
  display: grid;
  gap: 1em;
  grid-template-columns: 1.5fr 1fr;
  justify-content: center;
  padding: 2em 5em;
}
.founders-image-container {
  overflow: hidden;
}
.about-us-founders-image {
  max-width: 100%;
  width: 100%;
  height: 100%;
  max-height: 45em;
  object-fit: cover;
}

.founders-info-container {
  display: grid;
  grid-template-rows: min-content 1.5fr;
  outline-offset: 10px;
  max-width: 65em;
  outline: 1px solid rgb(216, 219, 188);
  /* max-width: 70%; */
  margin: 10px;
}

blockquote {
  /* display: flex; */
  /* justify-content: center; */
  /* border: 0.5px solid rgb(219, 219, 219); */
  padding: 4em 2em;
}
.quote-container {
  background-color: #8d6b51;
  border-radius: 2px;
  /* margin: 0 -10%; */
  /* width: calc(100% + 20%); */
  /* border: 1px solid black; */
}
.quotation {
  /* font-size: 2rem; */
  /* max-width: 350em; */
  color: rgb(255, 255, 255);
  quotes: "\201C""\201D""\2018""\2019";
  position: relative;
  font-weight: 400;
}

.quotation:before {
  content: open-quote;
  display: inline;
  height: 0;
  line-height: 0;
  left: -10px;
  position: relative;
  top: 30px;
  color: rgb(0, 0, 0);
  font-size: 3em;
}
.quotation::after {
  content: close-quote;
  display: inline;
  height: 0;
  line-height: 0;
  /* left: 20px; */
  position: absolute;
  bottom: -0.2em;
  right: 0em;
  color: rgb(0, 0, 0);
  font-size: 3em;
}
.founders-paragaraph {
  background-color: #f3f3e1;
  color: rgb(80, 80, 80);
  padding: 2em;
  display: flex;
  align-items: center;
}

.our-projects {
  margin: 5em 10% 0;
  border: 2px solid rgb(219, 219, 219);
  padding: 3em 4em;
}
.our-projects h2 {
  margin-bottom: 2rem;
}

.sarlahi-rug-container h3,
.thimi-ceramics-container h3 {
  margin-bottom: 1.1rem;
}

.thimi-ceramics-container {
  margin-top: 2rem;
  /* max-height: 35em; */
  overflow: hidden;
}

.image-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;
  margin: 0.5em 0;
}

#sarlahi-rug-image,
#thimi-ceramics-image {
  max-width: 100%;
  object-fit: cover;
  /* height: 100%; */
  /* float: left; */
}
#thimi-ceramics-image {
  max-height: 35em;
  width: 100%;
}
.thimi-ceramics-paragraph,
.sarlahi-paragraph {
  text-align: justify;
}

.sarlahi-paragraph + .sarlahi-paragraph {
  margin-top: 1rem;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

@media (max-width: 1200px) {
  .about-us-image-container {
    height: 100%;
  }
  .about-us-main img {
    min-height: 130vh;
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    object-position: bottom center;
  }
  .our-founders-main {
    grid-template-columns: 1fr;
    padding: 1em 1em;
  }
  .founders-info-container {
    margin: 1em;
  }
  .quote-container {
    padding: 0em 10em;
  }
  .founders-paragaraph {
    padding: 3em 10em;
  }
}
@media (max-width: 768px) {
  .about-us-main {
    margin-top: 5vh;
    height: auto;
    width: auto;
  }

  .our-values-section {
    padding: 2em 0 1em 0;
    margin: 2em 1.5em;
  }
  .values-grid {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .value {
    padding: 1em 2.5em;
  }
  .our-founders-section {
    margin-top: 1em;
    padding: 2em 0 1em 0;
  }

  .founders-info-container {
    display: flex;
    flex-direction: column;
    margin: 2em 1.5em;
  }

  blockquote {
    padding: 2em 2em;
    line-height: 2em;
  }
  .founders-paragaraph {
    /* line-height: 2rem; */
    /* max-width: 55em; */
    padding: 2em 6em;
    line-height: 2rem;
  }
  .quote-container {
    padding: 0em 1em;
  }

  .image-container {
    grid-template-columns: none;
    grid-template-rows: auto 1fr;
    margin: 0.5em 0;
  }
  .our-projects {
    margin: 2em 0 0;
    border: 2px solid rgb(219, 219, 219);
    padding: 1em;
  }
  .sarlahi-rug-container h3,
  .thimi-ceramics-container h3 {
    text-align: center;
  }
  .thimi-ceramics-paragraph,
  .sarlahi-paragraph {
    text-align: center;
  }
}
@media (max-width: 450px) {
  .about-us-main {
    margin-top: 10vh;
  }
  .about-us-main img {
    min-height: 160vh;
  }
  .about-us-paragraph-container {
    max-width: 90%;
  }
  .founders-paragaraph {
    padding: 2em 2em;
  }
}
