.logo-image {
  height: auto;
  width: 100%;
  max-width: clamp(6em, 4vw, 10em);
  -webkit-max-width: clamp(6em, 4vw, 10em);
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

nav {
  position: absolute;
  top: 0;
  min-width: 23em;
  display: flex;
  font-family: var(--alternate-font);
  padding: 0 3em;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  z-index: 1000;
  width: 100%;
  background-color: #ffffff;
  transition: background-color 500ms ease-in, opacity 800ms ease-in;
}

.nav-show {
  transform: translateY(0em);
  /* opacity: 1; */
}
.nav-link {
  font-size: clamp(0.8rem, 0.9vw, 3rem);
}

.nav-item {
  padding: 0.7em;
}

.nav-item a {
  text-decoration: none;
  color: rgb(49, 49, 49);
}

.nav-item a:hover {
  color: rgb(173, 175, 33);
}
.nav-collection-dropdown-hide {
  display: none;
}
#nav-rugs {
  position: relative;
}

.nav-collection-dropdown {
  position: absolute;
  left: -1em;
  /* display: none; */
}

.nav-collection-dropdown-ul {
  padding-top: 1.5em;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: max-content;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}
#nav-rugs:hover .nav-collection-dropdown-ul {
  max-height: 500px;
}
.nav-collection-dropdown-ul li {
  margin: 1em 1.5em;
}
.hamburger-menu {
  position: fixed;
  /* opacity: 70%; */
  top: 6%;
  right: 5%;
  width: 2.3em;
  height: 2.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1001;
  background: rgb(0, 0, 0);
}
.hamburger-menu-button {
  position: absolute;
  width: 1.5em;
  height: 0.25em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  background: rgb(255, 255, 255);
}
.hamburger-menu-button::after {
  top: -0.5em;
}
.hamburger-menu-button::before {
  top: 0.5em;
}

.hamburger-menu-button::after,
.hamburger-menu-button::before {
  content: "";
  position: absolute;
  right: 0;
  width: 1.5em;
  height: 0.25em;
  background: rgb(255, 255, 255);
  box-shadow: 0 2px 2px rgba(116, 116, 116, 0.2);
  transition: 0.5s;
}

.hamburger-menu-active .hamburger-menu-button {
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
}
.hamburger-menu-active .hamburger-menu-button::after {
  top: 0px;
  transform: rotate(45deg);
}

.hamburger-menu-active .hamburger-menu-button::before {
  top: 0px;
  transform: rotate(130deg);
}

.landing-navbar-active {
  flex-direction: column;
  padding-top: 2em;
  padding-bottom: 5em;
  top: 4%;
  width: 0;
  right: 3.5%;
  text-align: center;
  justify-content: start;
  align-items: center;
  position: fixed;
  height: auto;
  background: radial-gradient(50% 50% at 50% 50%, #ffffff 0%, #f9f9f9 100%);
  box-shadow: 0px 10px 23px 27px rgba(0, 0, 0, 0.26);
}
.nav-bottom-heading {
  margin-bottom: 2em;
}
.landing-navbar-active ul li {
  display: block;
}
.landing-navbar-active > .nav-items {
  margin-top: 1em;
}

@media (max-width: 768px) {
  nav {
    padding: 0 0.5em;
    min-width: auto;
  }
  .nav-item {
    display: none;
  }
  .hamburger-menu {
    /* opacity: 50%; */
    top: 5%;
    right: 5%;
    width: 2em;
    height: 2em;
  }
  .nav-link {
    font-size: clamp(1.6rem, 2vw, 3rem);
  }
  .landing-navbar-active {
    padding-top: 2em;
    padding-bottom: 5em;
    top: 2%;
    left: 2%;
    right: 2%;
    bottom: 5%;
    width: auto;
    min-width: auto;
  }
}
