@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.individualCollectionSection {
  margin-top: 7em;
  /* margin-left: 10em; */
  margin-bottom: 2em;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.individual-collection-header {
  height: 90vh;
  width: 100%;
  display: grid;
  grid-template-rows: 0.9fr min-content;
  gap: 1.5em;
  justify-items: center;
}
.individual-collection-nav {
  /* background-color: rgb(95, 25, 25); */
  width: 100%;
}
.individualCollectionBanner {
  /* margin: 2em; */
  /* min-height: 35em; */
  width: 100%;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}
.individualCollectionBanner h2 {
  font-weight: lighter;
  background-color: rgba(255, 255, 255, 0.904);
  color: black;
  text-align: center;
  width: 100%;
  padding: 1em 1em;
}
.individual-collection-description {
  text-align: center;
  align-self: flex-start;
  max-width: 70em;
  padding: 0 5em;
}
.individual-collection-description h2 {
  margin-bottom: 0.5em;
}

.individualCollectionItems {
  /* margin: 1em; */
  margin-bottom: 1em;
  /* max-width: 1300px; */
  min-width: 70%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
  background-color: white;
}
.individualCollectionItem {
  /* justify-self: center; */
  /* align-self: center; */
  /* flex-basis: 10em; */
  display: flex;
  /* background-color: rgb(180, 54, 54); */
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  height: auto;
  min-height: 25em;
  background-position: center;

  /* width: max-content; */
  min-width: 20em;
  margin-bottom: 2em;
  flex-basis: 25%;
}
.individualCollectionRugName {
  opacity: 0;
  word-break: break-all;
  /* display: none; */
}
.individualCollectionItem:hover {
  cursor: pointer;
}
.individualCollectionItem:hover .individualCollectionRugName {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.individual-collection-carpet-info {
  padding: 5em;
  display: flex;
  justify-content: center;
  gap: 2em;
  /* align-items: center; */
}
.outer-popup {
  position: fixed;
  display: flex;
  align-items: center;
  background-color: rgba(39, 39, 39, 0.432);
  /* justify-content: center; */
  /* width: 100%; */
  /* height: auto; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.inner-popup {
  /* width: minmax(1rem, 2rem); */
  /* max-width: 70%; */
  position: relative;

  /* width: clamp(50%, 90px, 60%); */
  max-width: 70em;
  /* max-width: 75em; */
  background-color: rgb(240, 240, 240);
  padding: 3.5em 5em;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  gap: 2em;
}

.individual-carpet-info-left-col {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 50vh;
  /* justify-self: center; */
  /* background-color: red; */
}
.individual-collection-main-picture {
  max-width: 100%;
  max-height: 100%;
}
.individual-collection-main-picture:hover {
  cursor: zoom-in;
}

.individual-collection-secondary-picture {
  margin-top: 1em;
  height: 3em;
  width: 3em;
}
.individual-carpet-info-right-col {
  /* background-color: rgb(216, 92, 92); */

  display: flex;
  flex-direction: column;
  gap: 0.5em;
  flex: 60%;
  /* padding-top: 1em; */
}
.form-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(51, 51, 51, 0.336);
  z-index: 100200;
  transition: all 10s ease;
}

.individual-carpet-form {
  /* height: 300px; */
  right: 0;
  bottom: 0;
  padding: 2em 1.5em 1em;
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  animation: rightToLeft 0.3s linear;
  z-index: 2147483647;
}
.close-individual-carpet-form {
  transform: translateX(100%);
}

@keyframes rightToLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.individual-carpet-form p {
  margin: 0.5em 0;
  max-width: 15em;
  /* text-align: justify; */
  /* word-break: ; */
  /* width: auto; */
}
.individual-carpet-form button {
  margin: 1.5em 0 2em;
}
.individual-carpet-form-close-button {
  margin: 1.5em 0 2em;
  background-color: white;
}
.individual-carpet-form input {
  min-height: 3.5em;
  min-width: 30em;
  margin-top: 1.5em;
}
.individual-carpet-description {
  margin: 0 0 0.5em 0;
}

.invalid-email {
  /* position: absolute; */
  color: rgba(0, 0, 0, 0.973);
  font-weight: bolder;
  padding: 0.5em 1em;
  background-color: rgb(255, 185, 185);
  border-radius: 2px;
  right: 10px;
  bottom: 10px;
}

.individual-carpet-get-quote-button {
  cursor: pointer;
  align-self: flex-start;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(0.8rem, 0.9vw, 3.5rem);
  margin-top: 0.5em;
  padding: 1em 2em;
  /* width: 20%; */
}

.individual-carpet-close-button-bottom,
.individual-carpet-form-close-button {
  cursor: pointer;
  background-color: rgb(88, 88, 88);
  color: white;
  justify-content: center;
  align-items: center;
  font-size: clamp(0.8rem, 0.9vw, 3.5rem);
  margin-top: 0.5em;
  padding: 1em 2em;
  display: none;
}
.close-popup {
  padding: 0.6em 0.7em;
  background-color: rgb(0, 0, 0);
  color: white;
  font-weight: bold;
  position: absolute;
  top: 1em;
  right: 1em;
  /* border-radius: 0.5rem; */
  transition: 0.3s background-color ease-in-out, 0.1s transform ease-in-out;
}
.close-popup:hover {
  transform: scale(1.05);
  cursor: pointer;
  background-color: rgb(0, 0, 0);
}

#img-full-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(36, 36, 36);
  z-index: 110000;
  padding: 2em 0;
}
#img-full-bg img {
  max-width: 100%;
  max-height: 50em;
}

#img-full-bg:hover {
  cursor: zoom-out;
}

@media (max-width: 768px) {
  .individual-collection-description {
    padding: 0;
  }
  .individual-collection-description p {
    margin: 0 0.5em;
  }
  .inner-popup {
    overflow-y: scroll;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    padding: 2em 0 2em 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    justify-items: center;
    gap: 2em;
  }
  .individual-carpet-info-left-col {
    max-height: 40vh;
  }
  .close-button {
    display: none;
  }

  .individual-carpet-get-quote-button,
  .individual-carpet-form-button {
    align-self: center;
    width: 40%;

    /* maxwidth: 100%; */
    /* margin: 0 1em; */
  }
  .individual-carpet-form button {
    margin-bottom: 1em;
  }
  .individual-carpet-close-button-bottom,
  .individual-carpet-form-close-button {
    display: flex;
    margin-top: 0;
    width: 40%;
  }
  .individual-collection-carpet-info {
    padding: 0em;
  }
  .individual-carpet-info-right-col {
    flex: 100%;
    align-items: center;
    text-align: center;
  }
  .individual-carpet-form {
    /* height: 300px; */
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 2em 1.5em 1em;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    animation: rightToLeft 0.3s linear;
    z-index: 600;
  }
  .individual-carpet-form p,
  .individual-carpet-form h3 {
    align-self: center;
  }

  /* .individual-carpet-form button {
    width: min-content;
  } */

  .invalid-email {
    position: absolute;
    padding: 0.5em 1em;
    top: 10px;
    left: 10px;
    right: auto;
    bottom: auto;
  }
  .individual-email-input {
    margin-top: 0.5em;
  }
  .individual-carpet-description {
    margin: 0 0.5em;
  }
  .individual-collection-main-picture:hover {
    cursor: default;
  }
  #img-full-bg:hover {
    cursor: default;
  }
}
