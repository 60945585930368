.collectionSection {
  text-align: center;
  padding: 0% 0 2% 0;
  /* outline: 1px solid black; */
  /* outline-offset: 2em; */
  overflow-x: hidden;
}
.collectionPageHeading {
  margin: 0.5em 0;
}

.collectionWrapper {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: repeat(4, 1fr);
  gap: 1em;
  margin-top: 6.5em;
}

a[id="Founders"] {
  grid-column: 1/3;
  grid-row: 2/3;
  min-height: 30em;
}

#Basics {
  grid-column: 2/3;
  grid-row: 3/5;
  width: 100%;
}
#Runner {
  grid-column: 1/3;
  min-height: 25em;
}

.collectionItem {
  text-decoration: none;
  /* margin: 2em 0; */
  display: flex;
  /* flex: 40%; */
  justify-content: center;
  align-items: center;
  color: white;
  min-height: 25em;
  transition: all 0.1s linear;
}

.collectionItem:hover {
  transform: scale(1.01);
  /* transition: all 0.1s ease-in-out; */
}

@media (max-width: 768px) {
  .collectionWrapper {
    display: flex;
    flex-direction: column;
  }

  a[id="Founder's Collection"] {
    grid-column: 1/2;
    grid-row: 2/3;
  }

  #Basics {
    grid-column: 1/2;
    grid-row: 3/4;
    width: 100%;
  }
}
